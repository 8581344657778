.footer-image {
	position: absolute;
	top: -294px;
    width: 100%;
	height: 368px;
    z-index: 1;
    background: url(../image/footer/footer.png) no-repeat 50%;
    pointer-events: none
}

.footer-v1 {
    margin: 250px 0 0 0 !important
}

footer footer-background {
	position: absolute;
    bottom: 7.25rem;
    left: 0;
	width: 100%;
    height: 18rem;
    z-index: -1;
    transform: translate3d(0, 0, 0)
}

footer footer-background figure {
    width: 100%;
    height: 15rem;
    margin: 0
}

footer footer-background .clouds {
	position: absolute;
    top: -2em;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../image/footer/clouds.png) repeat-x;
	background-repeat: repeat-x !important;
    background-size: 225em 15em
}

footer footer-background .background {
	position: absolute;
    top: -1em;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../image/footer/background.png) repeat-x;
	background-repeat: repeat-x !important;
    background-size: 225em 21.313em
}

footer footer-background .foreground {
	position: absolute;
	top: 1rem;
    right: 0;
    bottom: 0;
    left: 0;
	background: url(../image/footer/foreground.png) repeat-x;
    background-repeat: repeat-x !important;
    background-size: 225em 15em
}


/* Dark Mode CSS */

@media (prefers-color-scheme: dark) {
    
    :root:not([color-scheme]) footer {
        opacity: 0
    }
}

[color-scheme=dark] footer {
	opacity: 0
}